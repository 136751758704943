<template>
  <div class="talentCertification">
    <div class="steps">
      <div
        :class="[
          'steps-one',
          ,
          {
            finished:
              realNameAuditStatus == 1 ||
              auditStatus == 0 ||
              auditStatus == 1 ||
              auditStatus == 2,
          },
        ]"
      >
        <div class="serial-number">
          <div
            class="finished"
            v-if="
              realNameAuditStatus == 1 ||
              auditStatus == 0 ||
              auditStatus == 1 ||
              auditStatus == 2
            "
          >
            <img src="./img/finshed.png" alt="" />
          </div>
          <div class="number" v-else>1</div>
        </div>
        <div
          :class="[
            'steps-content',
            {
              'steps-content-active':
                realNameAuditStatus == 1 ||
                auditStatus == 0 ||
                auditStatus == 1 ||
                auditStatus == 2,
            },
          ]"
        >
          <div class="text">
            实名认证
            <span
              @click="gotAuthentication"
              v-if="realNameAuditStatus == -1 || realNameAuditStatus == 2"
              >去认证</span
            >
          </div>
          <div class="status">
            <span v-if="realNameAuditStatus == 1">审核通过</span>
            <span v-else-if="realNameAuditStatus == -1">未提交过申请</span>
            <span v-else-if="realNameAuditStatus == 2">审核驳回</span>
            <span v-else>待审核</span>
          </div>
        </div>
      </div>
      <div
        :class="[
          'steps-two',
          {
            finished:
              auditStatus == 0 ||
              auditStatus == 1 ||
              auditStatus == 2 ||
              (realNameAuditStatus == 1 && submitData.academicCertificate) ||
              submitData.titleCertificatesPic,
          },
        ]"
      >
        <div class="serial-number">
          <div class="finished" v-if="auditStatus == 1">
            <img src="./img/finshed.png" alt="" />
          </div>
          <div
            v-else
            :class="[
              'number',
              {
                'steps-number':
                  realNameAuditStatus == 1 ||
                  auditStatus == 0 ||
                  auditStatus == 1 ||
                  auditStatus == 2 ||
                  (realNameAuditStatus == 1 &&
                    submitData.academicCertificate) ||
                  submitData.titleCertificatesPic,
              },
            ]"
          >
            2
          </div>
        </div>
        <div
          :class="[
            'steps-content',
            {
              'steps-content-active':
                auditStatus == 0 ||
                auditStatus == 1 ||
                auditStatus == 2 ||
                (realNameAuditStatus == 1 && submitData.academicCertificate) ||
                submitData.titleCertificatesPic,
            },
          ]"
        >
          <div class="text">上传毕业证书</div>
          <div class="photo">
            <v-upload
              :deletable="auditStatus == -1 || auditStatus == 2"
              :imgUrl.sync="submitData.academicCertificate"
              :activityPicture.sync="submitData.academicCertificate"
              ref="load"
            ></v-upload>
          </div>
        </div>
      </div>
      <div
        :class="[
          'steps-three',
          {
            finished:
              auditStatus == 0 ||
              auditStatus == 1 ||
              auditStatus == 2 ||
              (realNameAuditStatus == 1 &&
                submitData.academicCertificate &&
                submitData.diplomaPic) ||
              submitData.titleCertificatesPic,
          },
        ]"
      >
        <div class="serial-number">
          <div class="finished" v-if="auditStatus == 1">
            <img src="./img/finshed.png" alt="" />
          </div>
          <div
            v-else
            :class="[
              'number',
              {
                'steps-number':
                  auditStatus == 0 ||
                  auditStatus == 1 ||
                  auditStatus == 2 ||
                  (realNameAuditStatus == 1 &&
                    submitData.academicCertificate &&
                    submitData.diplomaPic) ||
                  submitData.titleCertificatesPic,
              },
            ]"
          >
            3
          </div>
        </div>
        <div
          :class="[
            'steps-content',
            {
              'steps-content-active':
                auditStatus == 0 ||
                auditStatus == 1 ||
                auditStatus == 2 ||
                (realNameAuditStatus == 1 &&
                  submitData.academicCertificate &&
                  submitData.diplomaPic) ||
                submitData.titleCertificatesPic,
            },
          ]"
        >
          <div class="text">上传学位证书</div>
          <div class="photo">
            <v-upload
              :deletable="auditStatus == -1 || auditStatus == 2"
              :imgUrl.sync="submitData.diplomaPic"
              :activityPicture.sync="submitData.diplomaPic"
              ref="load"
            ></v-upload>
          </div>
        </div>
      </div>
      <div
        :class="[
          'steps-four',
          {
            finished:
              auditStatus == 0 ||
              auditStatus == 1 ||
              auditStatus == 2 ||
              (realNameAuditStatus == 1 &&
                submitData.academicCertificate &&
                submitData.diplomaPic) ||
              submitData.titleCertificatesPic,
          },
        ]"
      >
        <div class="serial-number">
          <div class="finished" v-if="auditStatus == 1">
            <img src="./img/finshed.png" alt="" />
          </div>
          <div
            v-else
            :class="[
              'number',
              {
                'steps-number':
                  auditStatus == 0 ||
                  auditStatus == 1 ||
                  auditStatus == 2 ||
                  (realNameAuditStatus == 1 &&
                    submitData.academicCertificate &&
                    submitData.diplomaPic) ||
                  submitData.titleCertificatesPic,
              },
            ]"
          >
            4
          </div>
        </div>
        <div
          :class="[
            'steps-content',
            {
              'steps-content-active':
                auditStatus == 0 ||
                auditStatus == 1 ||
                auditStatus == 2 ||
                (realNameAuditStatus == 1 &&
                  submitData.academicCertificate &&
                  submitData.diplomaPic) ||
                submitData.titleCertificatesPic,
            },
          ]"
        >
          <div class="text">上传职称证书</div>
          <div class="photo">
            <v-upload
              :maxCount="9"
              :showUpload="auditStatus == -1 || auditStatus == 2"
              :deletable="auditStatus == -1 || auditStatus == 2"
              :imgUrl.sync="submitData.titleCertificatesPic"
              :activityPicture.sync="submitData.titleCertificatesPic"
              ref="load"
            ></v-upload>
          </div>
        </div>
      </div>
      <div class="steps-five">
        <div class="serial-number">
          <div class="finished" v-if="auditStatus == 1">
            <img src="./img/finshed.png" alt="" />
          </div>
          <div
            v-else
            :class="[
              'number',
              {
                'steps-number':
                  auditStatus == 0 ||
                  auditStatus == 1 ||
                  auditStatus == 2 ||
                  (realNameAuditStatus == 1 &&
                    submitData.academicCertificate &&
                    submitData.diplomaPic) ||
                  submitData.titleCertificatesPic,
              },
            ]"
          >
            5
          </div>
        </div>
        <div
          :class="[
            'steps-content',
            {
              'steps-content-active':
                auditStatus == 0 || auditStatus == 1 || auditStatus == 2,
            },
          ]"
        >
          <div class="text">填写个人信息</div>
          <div class="info">
            <div class="item">
              <div class="label">毕业时间</div>
              <div class="value" @click="handleClickTIme">
                <span>{{
                  submitData.graduateTime ? submitData.graduateTime : "请选择"
                }}</span>
                <img src="./img/date.png" alt="" />
              </div>
            </div>
            <div class="item">
              <div class="label">毕业院校</div>
              <div class="input">
                <v-input
                  :disabled="auditStatus == 0 || auditStatus == 1"
                  v-model="submitData.graduateSchool"
                  placeholder="请输入"
                ></v-input>
              </div>
            </div>
            <div class="item">
              <div class="label">最高学历</div>
              <div class="value education" @click="handleClickEducation">
                <span>{{
                  submitData.highestEducation
                    ? submitData.highestEducation
                    : "请选择"
                }}</span>
                <img class="down-arrow" src="./img/down-arrow.png" alt="" />
              </div>
            </div>
            <div class="item">
              <div class="label">所学专业</div>
              <div class="input">
                <v-input
                  :disabled="auditStatus == 0 || auditStatus == 1"
                  v-model="submitData.major"
                  placeholder="请输入"
                ></v-input>
              </div>
            </div>
            <div class="item">
              <div class="label">职称</div>
              <div class="input textarea">
                <v-input
                  type="textarea"
                  :disabled="auditStatus == 0 || auditStatus == 1"
                  v-model="submitData.titleCertificates"
                  placeholder="请输入"
                ></v-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-btn">
      <div
        class="btn"
        v-if="auditStatus == -1 || auditStatus == 2"
        @click="confirmSubmit"
      >
        提交
      </div>
      <div class="btn stay" v-else-if="auditStatus == 0">待审核</div>
      <!-- <div class="btn fail" v-else-if="auditStatus == 2" @click="confirmSubmit">
        审核未通过 请重新填写
      </div> -->
      <div class="btn succeed" v-else>审核通过</div>
    </div>

    <v-dateTimePicker
      type="date"
      :isAuto="true"
      :value="submitData.graduateTime | dateFormat"
      :valueShow.sync="isShowDateTime"
      @confirmPicker="confirmSelectDate"
    ></v-dateTimePicker>
    <v-picker
      :defaultIndex="0"
      :columns="educationList"
      :valueShow="isShowPicker"
      :valueKey="valueKey"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import {
  userInfoUrl,
  getTalentsIntroduceInfoByUserId,
  saveOrUpdateTalentsIntroduce,
} from "./api.js";
import moment from "moment";
export default {
  name: "talentCertification",
  components: {},
  props: {},
  data() {
    return {
      realNameAuditStatus: -1, //"实名审核状态 -1:未提交过申请 0:待审核 1:审核通过 2:审核驳回")
      auditStatus: -1, //审核状态
      submitData: {
        id: "",
        academicCertificate: "", //学历证书
        diplomaPic: "", //学位证书
        graduateSchool: "", //毕业院校
        graduateTime: "", //毕业时间
        highestEducation: "", //最高学历
        major: "", //专业
        titleCertificates: "", //专业
        titleCertificatesPic: "", //职称
        tenantId: this.$store.state.tenantId,
        userId: this.$store.state.userId,
      },
      isFinished: true,
      isShowPicker: false,
      isShowDateTime: false,
      educationList: [
        {
          label: "小学",
          value: 1,
        },
        {
          label: "初中",
          value: 2,
        },
        {
          label: "高中",
          value: 3,
        },
        {
          label: "大专",
          value: 4,
        },
        {
          label: "本科",
          value: 5,
        },
        {
          label: "硕士研究生",
          value: 6,
        },
        {
          label: "博士研究生",
          value: 7,
        },
      ],
      valueKey: "label",
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    if (this.toRegister()) {
      await this.getUserInfo();
      await this.getTalentsIntroduceInfoByUserId();
    }
  },
  mounted() {},
  filters: {
    dateFormat: function (value) {
      if (value) {
        return moment(value).toDate();
      }
      return new Date();
    },
  },
  methods: {
    //去注册
    toRegister() {
      if (!this.userId || this.userId == 0) {
        this.$dialog
          .alert({
            title: "提示",
            message: "您还未注册小程序，请尽快完成注册～",
          })
          .then(() => {
            if (wx.miniProgram) {
              let toUrl = this.$route.path;
              let params = this.$route.query;
              wx.miniProgram.redirectTo({
                url: `/pages/register/index?isRegister=2&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
                  params
                )}`,
              });
            }
          });
        return false;
      }
      return true;
    },
    //点击毕业时间
    handleClickTIme() {
      if (this.auditStatus == -1 || this.auditStatus == 2) {
        this.isShowDateTime = true;
      }
    },
    //点击最高学历
    handleClickEducation() {
      if (this.auditStatus == -1 || this.auditStatus == 2) {
        this.isShowPicker = true;
      }
    },
    // 获取用户信息
    async getUserInfo() {
      let res = await this.$axios.get(`${userInfoUrl}?userId=${this.userId}`);
      if (res.code === 200) {
        const { realNameAuditStatus } = res.data;
        if ([null, undefined, ""].includes(realNameAuditStatus)) {
          this.realNameAuditStatus = -1;
        } else {
          this.realNameAuditStatus = realNameAuditStatus;
        }
      }
    },
    // 根据userId获取人才认证信息
    async getTalentsIntroduceInfoByUserId() {
      let res = await this.$axios.get(
        `${getTalentsIntroduceInfoByUserId}?userId=${this.userId}`
      );
      if (res.code === 200) {
        if (Object.keys(res.data).length > 0) {
          const { auditStatus } = res.data;
          Object.keys(this.submitData).forEach((key) => {
            this.submitData[key] = res.data[key];
          });
          if ([null, undefined, ""].includes(auditStatus)) {
            this.auditStatus = -1;
          } else {
            this.auditStatus = auditStatus;
            if (this.auditStatus == 2) {
              this.$dialog.alert({
                title: "提示",
                message: "审核未通过，请重新填写！",
              });
            }
          }
        }
      }
    },
    //提交saveOrUpdateTalentsIntroduce
    confirmSubmit() {
      if (!this.realValid()) {
        return;
      }
      if (this.validateCertificates() && this.validate()) {
        this.$axios
          .post(saveOrUpdateTalentsIntroduce, this.submitData)
          .then((res) => {
            if (res.code == 200) {
              this.getTalentsIntroduceInfoByUserId();
            }
          });
      }
    },
    validateCertificates() {
      if (!this.submitData.academicCertificate && this.submitData.diplomaPic) {
        this.$toast({ message: "请上传毕业证书", duration: 2000 });
        return false;
      }
      if (this.submitData.academicCertificate && !this.submitData.diplomaPic) {
        this.$toast({ message: "请上传学位证书", duration: 2000 });
        return false;
      }
      if (this.submitData.academicCertificate && this.submitData.diplomaPic) {
        return true;
      }
      // 如果职称证有，则毕业证书和学位证可有可无
      if (this.submitData.titleCertificatesPic) {
        return true; // 职称证有的话，其他证书不要求
      } else {
        this.$toast({ message: "请上传学历证明和职业证书！", duration: 2000 });
      }
    },
    validate() {
      if (!this.submitData.graduateTime) {
        this.$toast({ message: "请选择毕业时间", duration: 2000 });
        return false;
      }
      if (!this.submitData.graduateSchool) {
        this.$toast({ message: "请输入毕业院校", duration: 2000 });
        return;
      }
      if (!this.submitData.highestEducation) {
        this.$toast({ message: "请选择最高学历", duration: 2000 });
        return false;
      }
      if (!this.submitData.major) {
        this.$toast({ message: "请输入所学专业", duration: 2000 });
        return false;
      }
      return true;
    },
    gotAuthentication() {
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/xubPages/realNameAuthentication/index`,
        });
      }
    },
    realValid() {
      const result = this.realNameAuditStatus == 1;
      if (this.realNameAuditStatus == -1) {
        this.$toast({
          message: "您还未实名认证，请先实名认证再使用。",
          duration: 3000,
        });
      } else if (this.realNameAuditStatus == 0) {
        this.$toast({
          message: "您的实名认证审核中，通过实名认证后才可进行",
          duration: 3000,
        });
      } else if (this.realNameAuditStatus == 2) {
        this.$toast({
          message: "您的实名认证被驳回，请重新实名认证再使用！",
          duration: 3000,
        });
      }
      return result;
    },
    confirmSelectDate(value) {
      this.submitData.graduateTime = moment(value).format("YYYY-MM-DD");
    },
    confirmPick(node) {
      this.submitData.highestEducation = node.label;
      this.isShowPicker = false;
    },
    clickPickOverlay() {
      this.isShowPicker = false;
    },
    cancelPick() {
      this.isShowPicker = false;
    },
  },
};
</script>

<style scoped lang="less">
.talentCertification {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 20px 30px 0 30px;
  background-color: #fafafa;
  padding-bottom: calc(138px + constant(safe-area-inset-bottom));
  padding-bottom: calc(138px + env(safe-area-inset-bottom));
  .steps {
    width: 100%;
    box-sizing: border-box;
    padding: 48px 44px 52px 42px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(218, 216, 216, 0.5);
    border-radius: 16px;
    .steps-one,
    .steps-two,
    .steps-three,
    .steps-four,
    .steps-five {
      display: flex;
      box-sizing: border-box;
      position: relative;
      &.finished {
        &::before {
          background-color: #007eff !important;
        }
      }
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      &:not(:last-child) {
        &::before {
          content: "";
          position: absolute;
          left: 24px;
          top: 64px;
          position: absolute;
          height: calc(100% - 40px);
          width: 4px;
          border-radius: 6px;
          background-color: #d9d9d9;
          // border-left: 5PX dotted #e7e7e7;
          box-sizing: border-box;
        }
      }
      .serial-number {
        margin-right: 38px;
        box-sizing: border-box;
        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          line-height: 20px;
          color: #d9d9d9;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 3px solid #d9d9d9;
          box-sizing: border-box;
          &.steps-number {
            color: #fff;
            background-color: #007eff;
            border: none;
          }
        }
        .finished {
          width: 48px;
          height: 48px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .steps-content {
        background: #f8f8f8;
        border-radius: 16px;
        flex: 1;
        box-sizing: border-box;
        padding: 16px 16px 16px 60px;
        position: relative;
        &.steps-content-active {
          background: #007eff;

          // border: 2px solid #007eff;
          &::before {
            border-right-color: #007eff;
          }
          .text,
          .status {
            color: #ffffff;
          }
          .photo {
            ::v-deep .van-uploader__preview {
              border: none;
            }
          }
          .info {
            .item {
              .label {
                color: #ffffff;
                &::after {
                  color: #ffffff;
                }
              }
              .value {
                span {
                  color: #ffffff;
                }
              }
              .input {
                ::v-deep .van-cell {
                  .van-field__body {
                    input {
                      color: #ffff;
                    }
                    textarea {
                      color: #ffff;
                    }
                    .van-field__control:disabled {
                      color: #fff;
                      -webkit-text-fill-color: #fff;
                    }
                  }
                }
              }
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          left: -38px;
          top: 0%;
          border: 20px solid transparent;
          border-right-color: #f8f8f8;
          margin-top: 15px;
        }
        .text {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 30px;
          color: #007eff;
          line-height: 42px;
          display: flex;
          align-items: center;
          span {
            margin-left: 20px;
            box-sizing: border-box;
            padding: 5px 15px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 24px;
            color: #ffff;
            line-height: 34px;
            text-align: left;
            font-style: normal;
            background-color: #007eff;
            // border: 2px solid #007eff;
            border-radius: 40px;
          }
        }
        .photo {
          margin-top: 26px;
          display: flex;
          flex-wrap: wrap;
          ::v-deep .van-uploader__upload {
            width: 150px;
            height: 150px;
            border: 2px dashed rgba(0, 0, 0, 0.25);
          }
          ::v-deep .van-uploader__preview-image {
            width: 150px;
            height: 150px;
            object-fit: cover;
          }
        }
        .status {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #666666;
          line-height: 34px;
          margin-top: 10px;
        }
        .info {
          margin-top: 20px;
          .item {
            display: flex;
            align-items: center;
            &:not(:last-child) {
              margin-bottom: 30px;
            }
            .label {
              width: 30%;
              text-align: justify;
              text-align-last: justify;
              display: block;
              word-break: break-all;
              text-justify: distribute;
              position: relative;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 30px;
              color: #333333;
              line-height: 42px;
              margin-right: 25px;
              &::after {
                content: ":";
                position: absolute;
                right: -15px;
                color: #333333;
              }
            }
            .value {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              // min-height: 50px;
              border-radius: 8px;
              border: 2px solid #d8d8d8;
              box-sizing: border-box;
              padding: 8px 26px 8px 22px;
              &.education {
                padding-right: 32px;
                .down-arrow {
                  width: 16px;
                  height: 10px;
                }
              }
              span {
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 24px;
                color: rgba(0, 0, 0, 0.5);
                line-height: 34px;
              }
              img {
                width: 30px;
                height: 30px;
              }
            }
            .input {
              flex: 1;
              &.textarea {
                ::v-deep .van-cell {
                  .van-field__body {
                    line-height: 24px;
                    textarea::-webkit-input-placeholder {
                      color: rgba(0, 0, 0, 0.5);
                    }
                  }
                }
              }
              ::v-deep .van-cell {
                padding: 8px 26px 8px 22px;
                border: 2px solid #d8d8d8;
                border-radius: 8px;
                border: 2px solid #d8d8d8;
                background-color: inherit;
                .van-field__body {
                  line-height: 0;
                  font-size: 24px;
                  input::placeholder {
                    color: rgba(0, 0, 0, 0.5);
                  }
                }
              }
            }
          }
        }
      }
    }
    .steps-two,
    .steps-three,
    .steps-four {
      .steps-content {
        padding-bottom: 38px;
      }
    }
    .steps-five {
      .steps-content {
        padding-right: 34px;
        padding-bottom: 38px;
      }
    }
  }
  .footer-btn {
    margin-top: 50px;
    width: 100%;
    padding: 0 106px;
    box-sizing: border-box;
    .btn {
      width: 100%;
      box-sizing: border-box;
      background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
      border-radius: 10px;
      padding: 12px 0;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 32px;
      color: #ffffff;
      line-height: 44px;
      text-align: center;
      font-style: normal;
      &.stay {
        background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
      }
      &.fail {
        background: linear-gradient(180deg, #ef677d 0%, #d93747 100%);
      }
      &.succeed {
        background: linear-gradient(180deg, #5ee6c4 0%, #30c991 100%);
      }
    }
  }
}
</style>
